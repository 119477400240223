import React, { useEffect, useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { QrScanner } from '@yudiel/react-qr-scanner';
import ItemDetails from './ItemDetails';
import { ProgressSpinner } from 'primereact/progressspinner';
import { VirtualScroller } from 'primereact/virtualscroller';
import { classNames } from 'primereact/utils';
import Header from '../Header';
import Navbar from '../Navbar';
import RemoveCookie from '../hooks/removeCookie';
import moment from 'moment/moment';
import { FixedSizeList as List } from 'react-window';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import QrReader from 'react-weblineindia-qrcode-scanner'
// import { List } from "react-virtualized";

const TableAllProducts = ({ opentable, setOpenTable }) => {
    const [data, setData] = React.useState("Not Found");
    const toast = useRef(null);
    const [InventoryList, setInventoryList] = useState([])
    const [handleScanEnable, sethandleScanEnable] = useState(false)
    const [InputInv, setInputInv] = useState(0)
    const [openpanel, setOpenPanel] = useState({ data: [], status: false, item: {} })
    const [panelTab, setPanelTab] = useState('pending')
    const [width, setWidth] = useState(window.innerWidth)
    const [search, setSearch] = useState('')
    const [fix, setFix] = useState(true)


    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth)
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth)
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, [])

        return (width <= 768)
    }

    function setFixed() {
        if (window.scrollY >= 110) {
            // console.log(false)
            setFix(false)
        } else {
            setFix(true)
            // console.log(false)
        }
    }

    useMemo(() => {
        window.addEventListener('scroll', setFixed)
    }, [setFixed])

    // useMemo(async () => {
    //     console.log(localStorage.getItem('user_id'))
    //     var formData = new FormData()
    //     formData.append("inventoryallproduct", 1)
    //     formData.append("user_id", Number(localStorage.getItem('user_id')))
    //     await axios({
    //         method: "post",
    //         // url: 'http://localhost/stgc.local/api/sales_report.php',
    //         url: ENV.DOMAIN + 'inventory.php',
    //         data: formData,
    //     }).then((res) => {
    //         setInventoryList(res.data)
    //         console.log(res.data)
    //     })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    //     //     console.log(localStorage.getItem('user_id'))
    // }, [ENV])

    const syncData = async () => {
        setInventoryList([])
        // console.log(Number(localStorage.getItem('user_id')))
        var formData = new FormData()
        formData.append("inventoryallproduct", 1)
        formData.append("user_id", Number(localStorage.getItem('user_id')))
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            setInventoryList(res.data)
            // console.log(res.data)
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const searchData = (data, search, status) => {
        // let data = sales;
        if (status == 'pending') {
            if (search) {
                data = data?.filter((i) =>
                    (i?.product_name.toLowerCase().includes(search.trim().toLowerCase()) && (i?.inv_status) == 'pending')
                    ||
                    (i?.product_code.toLowerCase().includes(search.trim().toLowerCase()) && (i?.inv_status) == 'pending'));
            } else {
                data = data?.filter((i) => (i?.inv_status) == 'pending');
            }
        } else if (status == 'completed') {
            if (search) {
                data = data?.filter((i) =>
                    (i?.product_name.toLowerCase().includes(search.trim().toLowerCase())) && (i?.inv_status) == 'complete'
                    ||
                    (i?.product_code.toLowerCase().includes(search.trim().toLowerCase())) && (i?.inv_status) == 'complete');

            } else {
                data = data?.filter((i) => (i?.inv_status) == 'complete');
            }
        } else if (status == 'recount') {
            if (search) {
                data = data?.filter((i) =>
                    (i?.product_name.toLowerCase().includes(search.trim().toLowerCase())) && (i?.inv_status) == 'recount'
                    ||
                    (i?.product_code.toLowerCase().includes(search.trim().toLowerCase())) && (i?.inv_status) == 'recount');

            } else {
                data = data?.filter((i) => (i?.inv_status) == 'recount');
            }
        } else {
            if (search) {
                data = data?.filter((i) => (i?.product_name.toLowerCase().includes(search.trim().toLowerCase()))
                    || (i?.product_code.toLowerCase().includes(search.trim().toLowerCase())));
            }
        }

        // if (search) {
        //     data = data?.filter((i) => (i?.product_name.toLowerCase().includes(search.trim().toLowerCase()))
        //         || (i?.product_code.toLowerCase().includes(search.trim().toLowerCase()))
        //         || (i?.cat_name.toLowerCase().includes(search.trim().toLowerCase()))
        //     );
        // }
        // if(data.length <= 2) {
        //     window.scrollTo(0, 0)
        // }
        // if(data!=null) {
        return data
        // }
        // return []
    }

    const [styles, setStyles] = useState({})

    const pending = (data) => {
        // const _data = data?.filter((i) => (String(i?.curr_date) != String(i?.current_date))
        //     && (Number(i?.id) == Number(i?.product_id))
        //     && (Number(i?.user_id) == Number(localStorage.getItem('user_id'))) && (i?.inv_status != 'recount')).length;

        // console.log(data, _data, localStorage.getItem('user_id'))
        const _data = data?.filter((i) => (i?.inv_status) == 'pending').length;
        return _data
    }

    const completed = (data) => {
        const _data = data?.filter((i) => (i?.inv_status) == 'complete').length;
        // syncData()
        return _data
    }

    const recount = (data) => {
        // const _data = data?.reverse().filter((i) => (i?.inv_status == 'recount')).length;
        // const _data = data?.reverse().filter((i) => ((Number(i?.curr_stock) != Number(i?.curr_count))
        //     && String(i?.curr_date) == String(i?.current_date)
        //     && Number(i?.user_id) == Number(localStorage.getItem('user_id')))).length;
        const _data = data?.filter((i) => (i?.inv_status) == 'recount').length;
        // syncData()
        return _data
    }

    // function getWeeksInMonth(year, month) {
    //     const weeks = [],
    //       firstDate = new Date(year, month, 1),
    //       lastDate = new Date(year, month + 1, 0),
    //       numDays = lastDate.getDate();

    //     let dayOfWeekCounter = firstDate.getDay();

    //     for (let date = 1; date <= numDays; date++) {
    //       if (dayOfWeekCounter === 0 || weeks.length === 0) {
    //         weeks.push([]);
    //       }
    //       weeks[weeks.length - 1].push(date);
    //       dayOfWeekCounter = (dayOfWeekCounter - 1) % 7;
    //     }

    //     return weeks
    //       .filter((w) => !!w.length)
    //       .map((w) => ({
    //         year: year,
    //         start: w[0],
    //         end: w[w.length - 1],
    //         dates: w,
    //       }));

    //   return weeks;
    // }

    // console.log(getWeeksInMonth('2023', '9'));

    // var curr = new Date; // get current date
    // var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6

    // var firstday = new Date(curr.setDate(first)).toUTCString();
    // var lastday = new Date(curr.setDate(last)).toUTCString();

    // var startOfWeek = moment().startOf('week').toDate();
    // var endOfWeek   = moment().endOf('week').toDate();

    // var startDay = 1; //0=sunday, 1=monday etc.
    // var d = curr.getDay(); //get the current day
    // var weekStart = new Date(curr.valueOf() - (d<=0 ? 7-startDay:d-startDay)*86400000); //rewind to start day
    // var weekEnd = new Date(weekStart.valueOf() + 6*86400000); //add 6 days to get last day

    // console.log(format(firstday), format(lastday));
    // console.log(new Date())
    function AllRows({ data, index, key }) {

        console.log(searchData(InventoryList, search, panelTab)[index], index)
        // if(data[index]!=null) { //
        return (
            // <div>{index}</div>
            <ItemDetails key={index} rowData={data[index]} panelTab={panelTab} syncData={syncData} />
        );
        // }
        // return (<></>)

    }
    // const [items] = InventoryList; //useState(Array.from({ length: 1000000 }).map((_, i) => `Item #${i}`));

    const itemTemplate = (item, options) => {
        // const className = classNames('flex align-items-center p-2', {
        //     'surface-hover': options.odd
        // });
        // console.log(item)
        return (
            <ItemDetails rowData={item} panelTab={panelTab} syncData={syncData} />
            // <div>
            //     <div>{item.user_id}</div>
            //     <div>{item.product_name}</div>
            // </div>
            // <div className={className} style={{ height: options.props.itemSize + 'px' }}>
            //     {item}
            // </div>
        );
    };
    const [row, setRow] = useState(10)
    useEffect(() => {
        RemoveCookie('PHPSESSID')
        syncData()
    }, [])
    const [result, setresult] = useState("result")
    const handleScan = (data) => {
      setresult(data)
    }
    const handleError = (err) => {
      console.error(err)
    }
    return (
        <div className="vd_body">
        {/* <BarcodeScannerComponent
          width={"100%"}
          height={"100%"}
          onUpdate={(err, result) => {
            if (result) setData(result.text);
            else setData("Not Found");
          }}
        />
        <p>{data}</p> */}
        
        {/* <QrReader
          delay={100}
          style={{width:"100%"}}
          onError={(e)=>handleError(e)}
          onScan={(e)=>handleScan(e)}
          />
        <p>{result}</p> */}
            {/* Header Start */}
            {
                fix ?
                    <Header />
                    :
                    null
            }
            {/* Header Ends */}
            <div className={!useCheckMobileScreen() ? "content" : ""}>
                <div className="container">
                    {/* {
                        !useCheckMobileScreen() ?
                            <Navbar />
                            :
                            null
                    } */}
                    {/* Middle Content Start */}
                    {/* className={!useCheckMobileScreen() ? "vd_content-wrapper" : ""} */}
                    {/* className={!useCheckMobileScreen() ? "vd_container" : ""} */}
                    {/* className={!useCheckMobileScreen() ? "vd_content clearfix" : ""} */}
                    {/* className={!useCheckMobileScreen() ? "vd_content-section clearfix" : ""} */}
                    <div>
                        <div>
                            <div>


                                <div>
                                    <div className={!useCheckMobileScreen() ? '' : "row"}>
                                        <div>
                                            {
                                                (width <= 768) ?
                                                    <>
                                                        {
                                                            !fix ?
                                                                <div>
                                                                    <div style={{ position: "fixed", zIndex: 1, width: "100%" }}>
                                                                        <div style={{ backgroundColor: "#f0f0f0", transition: ".3s ease-n-out", position: "relative" }}>
                                                                            <div style={{ padding: 5, marginBottom: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                                <input onChange={(e) => setSearch(e.target.value)} placeholder={"Search "} style={{ height: 35, flexShrink: 1, padding: 5, outline: "none", borderRadius: 5, border: "0px solid #ddd" }} />
                                                                                <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                                                    <button onClick={() => setOpenTable('all')} style={{ border: "none", border: "1px solid rgb(167 167 167)", minWidth: 50, height: 34, backgroundColor: opentable == 'all' ? "#30aba9" : null, color: opentable == 'all' ? "#fff" : null, borderRadius: 5 }}>All</button>
                                                                                    <button onClick={() => setOpenTable('by_cat')} style={{ border: "none", border: "1px solid rgb(167 167 167)", minWidth: 50, height: 34, backgroundColor: opentable == 'by_cat' ? "#30aba9" : null, color: opentable == 'by_cat' ? "#fff" : null, borderRadius: 5 }}>By Category</button>
                                                                                </div>
                                                                            </div>
                                                                            <>
                                                                                {
                                                                                    panelTab == 'pending' ?
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 0 }}>
                                                                                            <div onClick={() => setPanelTab('pending')} style={{ padding: 5, lineHeight: 1, backgroundColor: "#30aba9", borderTopLeftRadius: 0, borderTopRightRadius: 25, color: "#fff", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>PENDING</span>
                                                                                                <span>{pending(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('pending')} style={{ padding: 5, lineHeight: 1, backgroundColor: "#30aba9", borderTopLeftRadius: 0, borderTopRightRadius: 100, color: "#fff", position: "absolute", zIndex: 1, width: "40%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>PENDING</span>
                                                                                                <span>{pending(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('completed')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'completed' ? "#30aba9" : "#fff", borderTopLeftRadius: panelTab == 'completed' ? 25 : 0, borderTopRightRadius: panelTab == 'completed' ? 25 : 0, color: panelTab == 'completed' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>COMPLETED</span>
                                                                                                <span>{completed(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", borderTopLeftRadius: panelTab == 'recount' ? 25 : 0, borderTopRightRadius: panelTab == 'recount' ? 0 : 0, color: panelTab == 'recount' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>RECOUNT</span>
                                                                                                <span>{recount(InventoryList)}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    panelTab == 'completed' ?
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 0 }}>
                                                                                            <div onClick={() => setPanelTab('pending')} style={{ padding: 5, lineHeight: 1, backgroundColor: "#fff", borderTopLeftRadius: 0, borderTopRightRadius: 0, color: "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>PENDING</span>
                                                                                                <span>{pending(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", position: "absolute", zIndex: 1 }}>
                                                                                                <div onClick={() => setPanelTab('completed')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'completed' ? "#30aba9" : "#fff", borderTopLeftRadius: 100, borderTopRightRadius: 100, position: "absolute", zIndex: 1, color: panelTab == 'completed' ? "#fff" : "#000", width: "45%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                    <span>COMPLETED</span>
                                                                                                    <span>{completed(InventoryList)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", borderTopLeftRadius: panelTab == 'recount' ? 25 : 0, borderTopRightRadius: panelTab == 'recount' ? 0 : 0, color: panelTab == 'recount' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>RECOUNT</span>
                                                                                                <span>{recount(InventoryList)}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    panelTab == 'recount' ?
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 0 }}>
                                                                                            <div onClick={() => setPanelTab('pending')} style={{ padding: 5, lineHeight: 1, backgroundColor: "#fff", borderTopLeftRadius: 0, borderTopRightRadius: 0, color: "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>PENDING</span>
                                                                                                <span>{pending(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('completed')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'completed' ? "#30aba9" : "#fff", borderTopLeftRadius: 0, borderTopRightRadius: 0, color: panelTab == 'completed' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>COMPLETED</span>
                                                                                                <span>{completed(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", borderTopLeftRadius: 100, borderTopRightRadius: 0, color: "#fff", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>RECOUNT</span>
                                                                                                <span>{recount(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "flex-end", position: "absolute", zIndex: 1 }}>
                                                                                                <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", borderTopLeftRadius: 100, borderTopRightRadius: 0, color: "#fff", width: "40%", position: "absolute", zIndex: 1, textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                    <span>RECOUNT</span>
                                                                                                    <span>{recount(InventoryList)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </>


                                                                            {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div onClick={() => setPanelTab('pending')} style={{ padding:5, lineHeight:1, backgroundColor: panelTab == 'pending' ? "#30aba9" : "#fff", color: panelTab == 'pending' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                        <span>PENDING</span>
                                                                        <span>{pending(InventoryList)}</span>
                                                                    </div>
                                                                    <div onClick={() => setPanelTab('completed')} style={{ padding:5, lineHeight:1, backgroundColor: panelTab == 'completed' ? "#30aba9" : "#fff", color: panelTab == 'completed' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                        <span>COMPLETED</span>
                                                                        <span>{completed(InventoryList)}</span>
                                                                    </div>
                                                                    <div onClick={() => setPanelTab('recount')} style={{ padding:5, lineHeight:1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", color: panelTab == 'recount' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                        <span>RECOUNT</span>
                                                                        <span>{recount(InventoryList)}</span>
                                                                    </div>
                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ height: 210, backgroundColor: "rgb(48, 171, 169)" }}></div>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <div style={{ transition: '.3s ease-in-out' }}>
                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                            {/* <div style={{ padding: 10, fontSize: 18 }}>
                                                                                Acc.: <label>{localStorage.getItem('firstname')}</label>
                                                                            </div> */}
                                                                        </div>
                                                                        <div style={{ padding: 5, marginBottom: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                            <input onChange={(e) => setSearch(e.target.value)} placeholder={"Search "} style={{ height: 35, flexShrink: 1, padding: 5, outline: "none", borderRadius: 5, border: "0px solid #ddd" }} />
                                                                            <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                                                <button onClick={() => setOpenTable('all')} style={{ border: "none", border: "1px solid rgb(167 167 167)", minWidth: 50, height: 34, backgroundColor: opentable == 'all' ? "#30aba9" : null, color: opentable == 'all' ? "#fff" : null, borderRadius: 5 }}>All</button>
                                                                                <button onClick={() => setOpenTable('by_cat')} style={{ border: "none", border: "1px solid rgb(167 167 167)", minWidth: 50, height: 34, backgroundColor: opentable == 'by_cat' ? "#30aba9" : null, color: opentable == 'by_cat' ? "#fff" : null, borderRadius: 5 }}>By Category</button>
                                                                            </div>
                                                                        </div>
                                                                            <>
                                                                                {
                                                                                    panelTab == 'pending' ?
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 0 }}>
                                                                                            <div onClick={() => setPanelTab('pending')} style={{ padding: 5, lineHeight: 1, backgroundColor: "#30aba9", borderTopLeftRadius: 0, borderTopRightRadius: 25, color: "#fff", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>PENDING</span>
                                                                                                <span>{pending(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('pending')} style={{ padding: 5, lineHeight: 1, backgroundColor: "#30aba9", borderTopLeftRadius: 0, borderTopRightRadius: 100, color: "#fff", position: "absolute", zIndex: 1, width: "40%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>PENDING</span>
                                                                                                <span>{pending(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('completed')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'completed' ? "#30aba9" : "#fff", borderTopLeftRadius: panelTab == 'completed' ? 25 : 0, borderTopRightRadius: panelTab == 'completed' ? 25 : 0, color: panelTab == 'completed' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>COMPLETED</span>
                                                                                                <span>{completed(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", borderTopLeftRadius: panelTab == 'recount' ? 25 : 0, borderTopRightRadius: panelTab == 'recount' ? 0 : 0, color: panelTab == 'recount' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>RECOUNT</span>
                                                                                                <span>{recount(InventoryList)}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    panelTab == 'completed' ?
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 0 }}>
                                                                                            <div onClick={() => setPanelTab('pending')} style={{ padding: 5, lineHeight: 1, backgroundColor: "#fff", borderTopLeftRadius: 0, borderTopRightRadius: 0, color: "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>PENDING</span>
                                                                                                <span>{pending(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", position: "absolute", zIndex: 1 }}>
                                                                                                <div onClick={() => setPanelTab('completed')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'completed' ? "#30aba9" : "#fff", borderTopLeftRadius: 100, borderTopRightRadius: 100, position: "absolute", zIndex: 1, color: panelTab == 'completed' ? "#fff" : "#000", width: "45%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                    <span>COMPLETED</span>
                                                                                                    <span>{completed(InventoryList)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", borderTopLeftRadius: panelTab == 'recount' ? 25 : 0, borderTopRightRadius: panelTab == 'recount' ? 0 : 0, color: panelTab == 'recount' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>RECOUNT</span>
                                                                                                <span>{recount(InventoryList)}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    panelTab == 'recount' ?
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 0 }}>
                                                                                            <div onClick={() => setPanelTab('pending')} style={{ padding: 5, lineHeight: 1, backgroundColor: "#fff", borderTopLeftRadius: 0, borderTopRightRadius: 0, color: "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>PENDING</span>
                                                                                                <span>{pending(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('completed')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'completed' ? "#30aba9" : "#fff", borderTopLeftRadius: 0, borderTopRightRadius: 0, color: panelTab == 'completed' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>COMPLETED</span>
                                                                                                <span>{completed(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", borderTopLeftRadius: 100, borderTopRightRadius: 0, color: "#fff", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                <span>RECOUNT</span>
                                                                                                <span>{recount(InventoryList)}</span>
                                                                                            </div>
                                                                                            <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "flex-end", position: "absolute", zIndex: 1 }}>
                                                                                                <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", borderTopLeftRadius: 100, borderTopRightRadius: 0, color: "#fff", width: "40%", position: "absolute", zIndex: 1, textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                                                    <span>RECOUNT</span>
                                                                                                    <span>{recount(InventoryList)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </>
                                                    :
                                                    <div style={{ position: "fixed", zIndex: 1, width: "99%" }}>
                                                        <div style={{ backgroundColor: "#f0f0f0", transition: ".3s ease-n-out", position: "relative" }}>
                                                            <div style={{ padding: 5, marginBottom: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                <input onChange={(e) => setSearch(e.target.value)} placeholder={"Search "} style={{ height: 35, flexShrink: 1, padding: 5, outline: "none", borderRadius: 5, border: "0px solid #ddd" }} />
                                                                <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                                    <button onClick={() => setOpenTable('all')} style={{ border: "none", border: "1px solid rgb(167 167 167)", minWidth: 50, height: 34, backgroundColor: opentable == 'all' ? "#30aba9" : null, color: opentable == 'all' ? "#fff" : null, borderRadius: 5 }}>All</button>
                                                                    <button onClick={() => setOpenTable('by_cat')} style={{ border: "none", border: "1px solid rgb(167 167 167)", minWidth: 50, height: 34, backgroundColor: opentable == 'by_cat' ? "#30aba9" : null, color: opentable == 'by_cat' ? "#fff" : null, borderRadius: 5 }}>By Category</button>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                <div onClick={() => {
                                                                    setPanelTab('pending')
                                                                    setRow(10)
                                                                }} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'pending' ? "#30aba9" : "#fff", color: panelTab == 'pending' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                    <span>PENDING</span>
                                                                    <span>{pending(InventoryList)}</span>
                                                                </div>
                                                                <div onClick={() => setPanelTab('completed')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'completed' ? "#30aba9" : "#fff", color: panelTab == 'completed' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                    <span>COMPLETED</span>
                                                                    <span>{completed(InventoryList)}</span>
                                                                </div>
                                                                <div onClick={() => setPanelTab('recount')} style={{ padding: 5, lineHeight: 1, backgroundColor: panelTab == 'recount' ? "#30aba9" : "#fff", color: panelTab == 'recount' ? "#fff" : "#000", width: "33%", textAlign: "center", border: "0px solid #ddd", display: "flex", flexDirection: "column", gap: 1, alignItems: "center", justifyContent: "center" }}>
                                                                    <span>RECOUNT</span>
                                                                    <span>{recount(InventoryList)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            {/* console.log(e.target.scrollTop+" === "+e.target.clientHeight) */}
                                            <div onScroll={(e) => {
                                                console.log(row, e.target.scrollTop + " === " + e.target.clientHeight)
                                                if (e.target.scrollTop >= 10) {
                                                    setRow(prev => prev + 2)
                                                } else {
                                                    setRow(10)
                                                }
                                            }} style={{ overflow: !fix ? "scroll" : "", height: '850px' }}>
                                                {/* {
                                                    console.log(searchData(InventoryList, search, panelTab).length, InventoryList.length)
                                                } */}
                                                {
                                                    InventoryList.length > 0 ?
                                                        <>
                                                            {/* <VirtualScroller items={searchData(InventoryList, search, panelTab)} itemSize={50} itemTemplate={itemTemplate} className="" style={{ width: '100%', height: '100vh' }} /> */}

                                                            {
                                                                searchData(InventoryList, search, panelTab).slice(0, row).map((item, key) => {
                                                                    return (
                                                                        <ItemDetails rowData={item} panelTab={panelTab} syncData={syncData} />
                                                                    )
                                                                })
                                                            }
                                                            {/* {
                                                                !fix ?
                                                                <VirtualScroller items={searchData(InventoryList, search, panelTab)} itemSize={50} itemTemplate={itemTemplate} className="" style={{ width: '100%', height: '100vh' }} />
                                                                :
                                                                searchData(InventoryList, search, panelTab).slice(0, 10).map((item, key) => {
                                                                    return (
                                                                        <ItemDetails rowData={item} panelTab={panelTab} syncData={syncData} />
                                                                    )
                                                                })
                                                            } */}
                                                        </>
                                                        :
                                                        <div style={{ width: "100%", display: "flex", alignItems: "center", padding: 10 }}>
                                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                                        </div>
                                                }
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* .vd_container */}
                    </div>
                    {/* .vd_content-wrapper */}
                    {/* Middle Content End */}
                </div>
                {/* .container */}
            </div>
            {/* .content */}
            {/* Footer Start */}
            {/* <footer className="footer-1" id="footer">
                <div className="vd_bottom ">
                    <div className="container">
                        <div className="row">
                            <div className=" col-xs-12">
                                <div className="copyright">
                                    Copyright ©2014 Venmond Inc. All Rights Reserved
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
        </div>
    );
}

export default TableAllProducts;
