import React from 'react'
//stgcinc888@gmail.com

const SUBSCRIPTION = '';

// const DOMAIN = 'http://bqmhospital.com/';
// const DOMAIN = 'https://pluggedin.tagaytaycafe.org/php/';
// const DOMAIN = 'http://192.168.1.99/';  ///stgc
// const IMG_URL = 'http://192.168.1.99/images/';  ///stgc
// const DOMAIN = 'http://192.168.1.10/stgc/';
// const DOMAIN = 'http://192.168.1.10:99/';
// const DOMAIN    = 'https://inv.stgc.tech/';
// const IMG_URL   = 'https://stgc.tech/pages/';
// const PRINTER_URL = 'https://inv.stgc.tech/api/print_receipt.php';
const DOMAIN    = 'https://cyong.store/api/';
const IMG_URL   = 'https://cyong.store/pages/';
const PRINTER_URL = 'https://cyong.store/api/print_receipt.php';
// const DOMAIN = 'http://192.168.1.18/stgc/';
// const IMG_URL = 'http://192.168.1.18/stgc/images/';
// const PRINTER_URL = 'http://192.168.1.18/stgc/api/print_receipt.php';
// const DOMAIN = 'https://stgc.tech/';
// const DOMAIN = 'http://192.168.14.116/pluggedin/php/';
// const DOMAIN = 'https://directselling.gblessnetwork.net/';

const ENV =  {
    DOMAIN,
    IMG_URL,
    PRINTER_URL
}

export default ENV
