import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Slide from 'react-reveal/Slide';
import ENV from '../../ENV';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import RemoveCookie from '../hooks/removeCookie';

const ItemDetails = ({ rowData, panelTab, syncData }) => {
    const toast = useRef(null);
    const [dialog, setDialog] = useState(false);
    const [InputInv, setInputInv] = useState('')
    const [lastData, setlastData] = useState([])
    const [openhistory, openHistory] = useState(false)
    const [historylist, HistoryList] = useState([])

    // console.log(rowData)
    
    // const [loop, setLoop] = useState([])


    useEffect(()=> {
        RemoveCookie('PHPSESSID')
    }, [])

    const hideDialog = () => {
        RemoveCookie('PHPSESSID')
        setDialog(false)
        setTimeout(() => {
            openHistory(false)
            HistoryList([])
        }, 500)
    };

    const getLastData = async () => {
        // var formData = new FormData()
        // formData.append("last_updated", 1)
        // formData.append("user_id", localStorage.getItem('user_id'))
        // formData.append("product_id", rowData.product_id)
        // await axios({
        //     method: "post",
        //     // url: 'http://localhost/stgc.local/api/sales_report.php',
        //     url: ENV.DOMAIN + 'inventory.php',
        //     data: formData,
        // }).then((res) => {
        //     setlastData(res.data[0])
        //     console.log(res.data)
        // })
        //     .catch((err) => {
        //         console.log(err)
        //     })
    }

    // useMemo(async () => {
    //     var formData = new FormData()
    //     formData.append("last_updated", 1)
    //     formData.append("user_id", localStorage.getItem('user_id'))
    //     formData.append("product_id", rowData.product_id)
    //     await axios({
    //         method: "post",
    //         // url: 'http://localhost/stgc.local/api/sales_report.php',
    //         url: ENV.DOMAIN + 'inventory.php',
    //         data: formData,
    //     }).then((res) => {
    //         setlastData(res.data[0])
    //         // console.log(res.data)
    //     })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }, [rowData])

    const getHistory = async () => {
        console.log(rowData.product_id)
        var formData = new FormData()
        formData.append("inv_history", 1)
        formData.append("product_id", rowData.product_id)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            HistoryList(res.data)
            // console.log(res.data[0])
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const SaveButton = async (data) => {
        
        // console.log(data.product_id, localStorage.getItem('user_id'))
        // const _data = rowData.filter((i) => {
        //     return i
        // });
        // const _data = JSON.parse(rowData.inv_json);
        // const _data_ = _data.reverse().map((item, key) => {
        //     return item
        // })
        // const loopData = [..._data_, { 
        //     "id": "4", 
        //     "product_id": "4", 
        //     "date": new Date('Y'), 
        //     "time": "4", 
        //     "count": "4", 
        //     "stock": "4", 
        //     "user_id": "4"
        // }]
        // console.log(JSON.stringify(loopData))
        // return;
        // console.log(data.product_id, localStorage.getItem('user_id'))
        var formData = new FormData()
        if (InputInv != '') {
            formData.append("save_inventory", 1)
            formData.append("user_id", localStorage.getItem('user_id'))
            formData.append("product_id", data.product_id)
            formData.append("inputinv", InputInv)
            // formData.append("prev_count", lastData.prev_count)
            // formData.append("prev_date", lastData.prev_date)
            // formData.append("prev_time", lastData.prev_time)
            await axios({
                method: "post",
                // url: 'http://localhost/stgc.local/api/sales_report.php',
                url: ENV.DOMAIN + 'inventory.php',
                // url: 'http://192.168.1.11/stgc/inventory.php',
                data: formData,
            }).then((res) => {
                // setInventoryList(res.data)
                // console.log(res.data)
                if (res.data == 'success') {
                    // console.log(res.data)
                    setInputInv('')
                    // setOpenPanel({ data: [], status: false, item: {} });
                    syncData()
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfuly Saved', life: 3000 });
                    setDialog(false)
                    RemoveCookie('PHPSESSID')
                } else {

                }
            })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const UpdateButton = async (data) => {
        var formData = new FormData()
        formData.append("update_inventory", 1)
        formData.append("user_id", localStorage.getItem('user_id'))
        formData.append("product_id", data.product_id)
        formData.append("inputinv", InputInv)
        // formData.append("stock", data.curr_stock)
        await axios({
            method: "post",
            // url: 'http://localhost/stgc.local/api/sales_report.php',
            url: ENV.DOMAIN + 'inventory.php',
            data: formData,
        }).then((res) => {
            RemoveCookie('PHPSESSID')
            // setInventoryList(res.data)
            // console.log((res.data))
            setInputInv('')
            // setOpenPanel({ data: [], status: false, item: {} });
            syncData()
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfuly Saved', life: 3000 });
            setDialog(false)
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const DialogFooter = (
        <React.Fragment>
            {
                openhistory ?
                    <Button label="Back" icon="pi pi-back" outlined onClick={() => openHistory(false)} />
                    :
                    <>
                        {
                            panelTab == 'completed' ?
                                <div style={{
                                    display: "flex", flexDirection: "column",
                                    fontSize: 14, color: "#30aba9", fontWeight: 600, textAlign: "center"
                                }}>
                                    <div>COMPLETED</div>
                                    <div>
                                        <Button label="History" icon="pi pi-history" outlined onClick={() => {
                                            openHistory(true)
                                            getHistory()
                                        }} />
                                    </div>
                                </div>
                                :
                                <>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <div>
                                            <Button label="History" icon="pi pi-history" outlined onClick={() => {
                                                openHistory(true)
                                                getHistory()
                                            }} />
                                        </div>
                                        <div>
                                            <Button label="Cancel" icon="pi pi-times" outlined onClick={() => {
                                                setDialog(false)
                                                HistoryList([])
                                            }} />
                                            {/* saveNewProduct() */}
                                            {
                                                panelTab == 'pending' ?
                                                    <>
                                                        {
                                                            InputInv != '' ?
                                                                <Button label="Save" icon="pi pi-check" onClick={() => SaveButton(rowData)} />
                                                                :
                                                                <Button label="Save" disabled icon="pi pi-check" />
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                panelTab == 'recount' ?
                                                    <>
                                                        {
                                                            InputInv != '' ?
                                                                <Button label="Update" icon="pi pi-check" onClick={() => UpdateButton(rowData)} />
                                                                :
                                                                <Button label="Update" disabled icon="pi pi-check" />
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </>
                        }
                    </>
            }
        </React.Fragment>
    );

    if(rowData?.product_name==null)
    {
        return <></>
    }

    // console.log(rowData)

    return (
        <div>
            <Toast ref={toast} />
            <Dialog visible={dialog} style={{ width: '100rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                <>
                    <div style={{ fontSize: 15 }}>{rowData?.product_name}</div>
                    <div><b>UPC: {rowData?.product_code}</b></div>
                </>
            } modal className="p-fluid" footer={DialogFooter} onHide={hideDialog}>
                {
                    openhistory ?
                        <div style={{ height: 300, overflowX: "auto" }}>
                            <>
                                {
                                    historylist.length > 0 ?
                                        <>
                                            {
                                                historylist.map((item, key) => {
                                                    return (
                                                        <div key={key} style={{ borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd" }}>
                                                            <div>
                                                                <span style={{ fontWeight: 700 }}>{item.firstname}</span>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                                                {/* <span>Prev Count: {item.count}</span> */}
                                                                <span>Count: {item.count}</span>
                                                            </div>
                                                            <div>
                                                                <span>{item.date} {item.time}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <div style={{ textAlign: "center" }}>Please wait...</div>
                                }
                            </>
                        </div>
                        :
                        <div>
                            <div>
                                <img src={ENV.IMG_URL + rowData?.product_image} style={{ width: "100%", height: 200 }} />
                            </div>
                            {
                                panelTab == 'completed' ?
                                    <div style={{ textAlign: "center" }}>Actual Stock
                                        <div style={{ fontSize: 15, fontWeight: "600" }}>{rowData?.curr_stock ?? 0}</div>
                                    </div>
                                    :
                                    null
                            }
                            <div style={{ paddingTop: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: 5, borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd" }}>
                                <div style={{ textAlign: "center", width: "50%" }}>Last Count
                                    <div style={{ fontSize: 15, fontWeight: "600" }}>{rowData?.count}</div>
                                    <div>
                                        <div style={{ fontSize: 10, fontWeight: "500" }}>{rowData?.date != '' ? rowData?.date : '0000-00-00 00:00:00'}</div>
                                    </div>
                                </div>
                                <div style={{ textAlign: "center", width: "50%" }}>Updated Count
                                    <div style={{ fontSize: 15, fontWeight: "600" }}>{rowData?.curr_count}</div>
                                    <div>
                                        <div style={{ fontSize: 10, fontWeight: "500" }}>{rowData?.curr_date != '' ? rowData?.curr_date : '0000-00-00 00:00:00'}</div>
                                    </div>
                                </div>
                                {/* <div style={{ textAlign: "right" }}>Last Updated
                                        <div style={{ fontSize: 15, fontWeight: "600" }}>{rowData?.date ?? '0000-00-00'}</div>
                                        <div style={{ fontSize: 15, fontWeight: "600" }}>{rowData?.time ?? '0000-00-00'}</div>
                                    </div> */}
                            </div>
                            {
                                panelTab != 'completed' ?
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: 10 }}>
                                        <input type='number' onChange={(e) => setInputInv(e.target.value)} value={InputInv} placeholder={rowData?.curr_count} style={{ height: 50, width: 120, fontSize: 40, textAlign: "center" }} />
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }
            </Dialog>
            {/* <Slide down> */}
            <div onClick={() => { 
                getLastData()
                setDialog(true)
                 }} style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid #ddd", borderRadius:0, padding: 5, backgroundColor: "#30aba9", color: "#fff" }}>
                <div style={{ lineHeight: 1 }}>
                    <label>{rowData?.product_name}</label>
                </div>
                <div style={{ lineHeight: 1 }}>
                    <div>UPC: {rowData?.product_code}</div>
                    {
                        rowData?.cat_name ?
                            <div>Cat: {rowData?.cat_name}</div>
                            :
                            null
                    }
                </div>
                    {/* <div style={{ textAlign: "center" }}>Prev Stock
                            <div style={{ fontSize: 15, fontWeight: "600" }}>{rowData?.prev_stock ?? 0}</div>
                        </div> */}
                    {
                        panelTab == 'pending' ?
                        <div style={{ paddingTop: 5, display: "flex", flexDirection: "row", justifyContent: "center", paddingBottom: 5 }}>
                            <div style={{ textAlign: "center", width: "50%" }}>Last Count
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.count ?? 0}</div>
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_lastdate ?? '0000-00-00 00:00:00'}</div>
                            </div>
                        </div> 
                        :
                        <></>
                    }
                    {
                        panelTab == 'completed' ?
                        <div style={{ paddingTop: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: 5 }}>
                            <div style={{ textAlign: "center", width: "50%" }}>Last Count
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.count ?? 0}</div>
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_lastdate ?? '0000-00-00 00:00:00'}</div>
                            </div>
                            <div style={{ textAlign: "center", width: "50%" }}>Current Count
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_count ?? 0}</div>
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_date ?? '0000-00-00 00:00:00'}</div>
                            </div>
                        </div> 
                        :
                        <></>
                    }
                    {
                        panelTab == 'recount' ?
                        <div style={{ paddingTop: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: 5 }}>
                            <div style={{ textAlign: "center", width: "50%" }}>System Stock
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_stock ?? 0}</div>
                                {/* <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_lastdate ?? '0000-00-00 00:00:00'}</div> */}
                            </div>
                            <div style={{ textAlign: "center", width: "50%" }}>Last Count
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_count ?? 0}</div>
                                <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_date ?? '0000-00-00 00:00:00'}</div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {/* <div style={{ textAlign: "center", width: "50%" }}>Current Count
                        <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_count ?? 0}</div>
                        <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.curr_date ?? '0000-00-00 00:00:00'}</div>
                    </div> */}
                    {/* <div style={{ textAlign: "right" }}>Last Updated
                        <div style={{ lineHeight: 1 }}>
                            <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.date ?? '0000-00-00'}</div>
                            <div style={{ fontSize: 10, fontWeight: "600" }}>{rowData?.time ?? '0000-00-00'}</div>
                        </div>
                    </div> */}
            </div>
            {/* </Slide> */}
        </div>
    );
}

export default ItemDetails;
