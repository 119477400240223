import React, { useState } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import { useEffect } from 'react';

const Login = () => {

    // console.log(process.env.REACT_APP_API_URL)

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    // console.log()

    // const history = useHistory()
    // const { refid } = useParams();

    // console.log(refid)
    // id="pages" class="full-layout no-nav-left no-nav-right  nav-top-fixed background-login     responsive remove-navbar login-layout   clearfix" data-active="pages "  data-smooth-scrolling="1"
    // if (!useCheckMobileScreen()) {
    document.body.className = 'full-layout no-nav-left no-nav-right  nav-top-fixed background-login     responsive remove-navbar login-layout   clearfix';
    document.body.id = 'pages';
    //     document.body.style.backgroundImage = 'url(https://assets.nst.com.my/images/articles/060616LET02pix1.jpg.transformed.jpg)';
    //     document.body.style.backgroundRepeat = 'no-repeat';
    //     document.body.style.backgroundSize = 'cover';
    //     document.body.style.backgroundPosition = 'center center';
    // } else {
    //     document.body.className = 'hold-transition login-page';
    //     document.body.style.backgroundImage = 'url(https://www.verdict.co.uk/wp-content/uploads/2019/08/water-harvester.jpg)';
    //     document.body.style.backgroundRepeat = 'repear-y';
    //     document.body.style.backgroundSize = 'cover';
    //     document.body.style.backgroundPosition = 'center';
    // }


    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')



    const LoginBtn = async (event) => {
        event.preventDefault();
        console.log(username, password)

        var formData = new FormData();
        formData.append("login", 1);
        formData.append("username", username);
        formData.append("password", password);

        if (username == '' && password == '') {
            return;
        }

        await axios({
            method: "post",
            // url: ENV.DOMAIN + 'api/login.php',
            url: ENV.DOMAIN + 'login.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data[0].users_role.activity_category)
            if (res.data[0].status == 'success') {
                swal({
                    title: "Done!",
                    text: "Successfull Login",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    // localStorage.setItem('user_id', res.data[0].user_id)
                    // localStorage.setItem('username', res.data[0].username)
                    // localStorage.removeItem('adminUser')
                    if (res.data[0].status == 'success') {
                        localStorage.setItem('user_id', res.data[0].user_id)
                        localStorage.setItem('username', res.data[0].username)
                        localStorage.setItem('firstname', res.data[0].firstname)
                        localStorage.setItem('users_role', res.data[0].users_role)
                        if (res.data[0].user_id == 1 || res.data[0].user_id == 2) {                            
                            window.location.assign('/inventory/review')
                        } else {
                            window.location.assign('/inventory')
                        }
                    }
                    //  else {
                    //     window.location.assign('/profile')
                    // }
                    // setUsername('')
                    // setPassword('')
                });
            } else {
                alert('invalid Username and Password')
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }






    return (

        <>

            <div className="vd_body">
                {/* Header Start */}
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper" style={{ minHeight: 8 }}>
                            <div className="vd_container" style={{ minHeight: 8 }}>
                                <div className="vd_content clearfix">
                                    <div className="vd_content-section clearfix">
                                        <div className="vd_login-page">
                                            <div className="panel widget">
                                                <div className="panel-body">
                                                    <div className="" style={{ padding: 20, textAlign: "center", width: "100%" }}> 
                                                        <img src="https://cyong.store/logo.png" alt="logo" style={{width: 200, borderRadius: "100%"}} /> 
                                                    </div>
                                                    <form onSubmit={LoginBtn} className="form-horizontal" id="login-form">
                                                        <div className="alert alert-danger vd_hidden">
                                                            <button type="button" className="close" data-dismiss="alert" aria-hidden="true"><i className="icon-cross" /></button>
                                                            <span className="vd_alert-icon"><i className="fa fa-exclamation-circle vd_red" /></span><strong>Oh snap!</strong> Change a few things up and try submitting again. </div>
                                                        <div className="alert alert-success vd_hidden">
                                                            <button type="button" className="close" data-dismiss="alert" aria-hidden="true"><i className="icon-cross" /></button>
                                                            <span className="vd_alert-icon"><i className="fa fa-check-circle vd_green" /></span><strong>Well done!</strong>. </div>
                                                        <div className="form-group  mgbt-xs-20">
                                                            <div className="col-md-12">
                                                                <div className="label-wrapper sr-only">
                                                                    <label className="control-label" htmlFor="email">Username</label>
                                                                </div>
                                                                <div className="vd_input-wrapper" id="email-input-wrapper"> <span className="menu-icon"> <i className="fa fa-envelope" /> </span>
                                                                    <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} id="email" name="email" className="required" required />
                                                                </div>
                                                                <div className="label-wrapper">
                                                                    <label className="control-label sr-only" htmlFor="password">Password</label>
                                                                </div>
                                                                <div className="vd_input-wrapper" id="password-input-wrapper"> <span className="menu-icon"> <i className="fa fa-lock" /> </span>
                                                                    <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} id="password" name="password" className="required" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="vd_login-error" className="alert alert-danger hidden"><i className="fa fa-exclamation-circle fa-fw" /> Please fill the necessary field </div>
                                                        <div className="form-group">
                                                            <div className="col-md-12 text-center mgbt-xs-5">
                                                                <button className="btn vd_bg-green vd_white width-100" type="submit" id="login-submit">Login</button>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6">
                                                                        {/* <div className="vd_checkbox">
                                                                            <input type="checkbox" id="checkbox-1" defaultValue={1} />
                                                                            <label htmlFor="checkbox-1"> Remember me</label>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="col-xs-6 text-right">
                                                                        {/* <div className> <a href="pages-forget-password.php">Forget Password? </a> </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {/* Panel Widget */}
                                        </div>
                                        {/* vd_login-page */}
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                {/* <footer className="footer-2" id="footer">
                    <div className="vd_bottom ">
                        <div className="container">
                            <div className="row">
                                <div className=" col-xs-12">
                                    <div className="copyright text-center">
                                        Copyright ©2014 Venmond Inc. All Rights Reserved
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
                {/* Footer END */}
            </div>


        </>

    );
}

export default Login;
