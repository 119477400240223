import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";

// import Homepage from "./components/Homepage";
import Login from './screens/Login'
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard";
import Profile from "./screens/Profile";
import axios from "axios";
import ENV from "./screens/ENV";
import { useEffect, useMemo, useState } from "react";
import Downline from "./screens/Downline";
import Direct from "./screens/Direct";
import Unpaid from "./screens/Unpaid";
import Product from "./screens/Product";
import InventoryAssignment from "./screens/InventoryAssignment";
import Sales from "./screens/Sales";
import Printer from "./screens/Printer";
import SalesReport from "./screens/SalesReport";
import { AppProvider } from "./screens/context/AppContext";
import Inventory from "./screens/Inventory";
import InventoryReview from "./screens/InventoryReview";
import InventoryCategory from "./screens/InventoryCategory";
import InventoryCurrent from "./screens/InventoryCurrent";
import AddCustomerPage from "./screens/AddCustomerPage";
import InventoryPending from "./screens/InventoryPending";
import UserControl from "./screens/UserControl";
import Stockin from "./screens/Stockin";


function App() {
    const [userInfo, setUserInfo] = useState(null);
    return (
        <AppProvider>
            <Router>
                <Switch>
                    <Route exact={true} path="/">
                        {
                            localStorage.getItem('user_id') != null ? 
                            <>
                            {
                                localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2 ? 
                                <InventoryCurrent userInfo={userInfo} /> 
                                :
                                <Inventory userInfo={userInfo} /> 
                            }
                            </>
                            :
                            <Login />
                        }
                    </Route>
                    <Route exact={true} path="/inventory">
                        {
                            localStorage.getItem('user_id') != null ? 
                            <>
                            {
                                localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2? 
                                <InventoryCurrent userInfo={userInfo} /> 
                                : 
                                <Inventory userInfo={userInfo} /> 
                            }
                            </>
                            :
                            <Login />
                        }
                    </Route>
                    <Route exact={true} path="/inventory/current">
                        {
                            localStorage.getItem('user_id') != null ? 
                            <>
                            {
                                localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2? 
                                <InventoryCurrent userInfo={userInfo} /> 
                                : 
                                <Inventory userInfo={userInfo} /> 
                            }
                            </>
                            :
                            <Login />
                        }
                    </Route>
                    <Route exact={true} path="/inventory/review">
                        {
                            localStorage.getItem('user_id') != null ? 
                            <>
                            {
                                localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2? 
                                <InventoryAssignment userInfo={userInfo} hide={true} /> 
                                : 
                                <Inventory userInfo={userInfo} /> 
                            }
                            </>
                            :
                            <Login />
                        }
                    </Route>
                    <Route exact={true} path="/inventory/review/:user_id/:cat_id">
                        {
                            localStorage.getItem('user_id') != null ? 
                            <>
                            {
                                localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2? 
                                <InventoryCategory userInfo={userInfo} /> 
                                : 
                                <Inventory userInfo={userInfo} /> 
                            }
                            </>
                            :
                            <Login />
                        }
                    </Route>
                    <Route exact={true} path="/inventory/review/:cat_id/:product_id/:year">
                        {
                            localStorage.getItem('user_id') != null ? 
                            <>
                            {
                                localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2? 
                                <InventoryReview userInfo={userInfo} /> 
                                : 
                                <Inventory userInfo={userInfo} /> 
                            }
                            </>
                            :
                            <Login />
                        }
                    </Route>
                    <Route exact={true} path="/inventory/review/:user_id/:cat_id/:product_id">
                        {
                            localStorage.getItem('user_id') != null ? 
                            <>
                            {
                                localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2? 
                                <InventoryReview userInfo={userInfo} /> 
                                : 
                                <Inventory userInfo={userInfo} /> 
                            }
                            </>
                            :
                            <Login />
                        }
                    </Route>
                    <Route exact={true} path="/inventory/review/:user_id/:cat_id/:product_id/:year">
                        {
                            localStorage.getItem('user_id') != null ? 
                            <>
                            {
                                localStorage.getItem('user_id') == 1 || localStorage.getItem('user_id') == 2? 
                                <InventoryReview userInfo={userInfo} /> 
                                : 
                                <Inventory userInfo={userInfo} /> 
                            }
                            </>
                            :
                            <Login />
                        }
                    </Route>
                </Switch>
            </Router>
        </AppProvider>
    )
}

export default App;
